import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import 'react-phone-number-input/style.css'
import { SearchInput } from './search/SearchInput'
import { useFormState } from 'react-hook-form'

export const Search = ({ user, form, setUser }) => {
  useEffect(() => {}, [])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [results, setResults] = useState(null)
  return (
    <>
      <div className={'flex flex-col items-center font-noto'}>
        <SearchInput
          user={user}
          form={form}
          setResults={setResults}
          setUser={setUser}
        />
      </div>
    </>
  )
}
