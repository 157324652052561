import { CopyToClipboard } from 'react-copy-to-clipboard'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Button } from '@mui/material'
import { Logo } from '../assets/logo'
export const Developers = () => {
  const OPTIONS = [
    //   {
    //     code: `<script type='text/javascript'>
    //   let div = document.createElement('div') div.style.cssText =
    //   'padding: 14px; position: fixed; bottom: 0; left: 0; width: 100vw;
    //   z-index: 1000; display: flex; justify-content: flex-end;
    //   align-items: center; pointer-events: none;' let button =
    //   document.createElement('button') button.textContent = 'VoteFlare'
    //   button.style.cssText = 'font-weight: 600; border-radius: 10px;
    //   padding: 8px; background-color: rgb(0 5 29); color: white; border:
    //   1px white solid; cursor: pointer; pointer-events: all;' let origin =
    //   window.location.href button.onclick = () =>{' '}
    //   {window.open('http://localhost:3000/?origin=' + origin, '_blank')}
    //   div.appendChild(button) document.body.appendChild(div)
    // </script>`,
    //     title: 'Script Tag',
    //     desc: 'This is the script tag that you can use to add VoteFlare link to your website.',
    //     button: <Logo width={50} />,
    //   },
    {
      code: `<a href="https://voteflare.org" target='_blank' rel='noreferrer'>
      <div style="color: #000d29; width: 250px; cursor: pointer; background-color: rgb(224 242 254); border-radius: 10px; display: flex; flex-direction: column; align-items: center; padding: 12px; gap: 4px;">
        <div style="display: flex; align-items: center; gap: 10px;">
          <svg width='32px' viewBox='0 0 57.277 72.493'>
            <path
              d='M55.488,44.924l-9.243-15.278c-.364-.602-1.018-.969-1.722-.965l-29.742.156c-.631.002-1.224.302-1.6.809L1.894,44.871c-.256.345-.394.763-.394,1.192v22.93c0,1.105.895,2,2,2h50.277c1.105,0,2-.895,2-2v-23.033c0-.365-.1-.724-.289-1.036Z'
              stroke='#000654' stroke-width='3' fill='transparent'></path>
            <line x1='41.175' y1='36.372' x2='17.175' y2='36.372' stroke='#000654' stroke-width='3' stroke-linecap='square'>
            </line>
            <polygon points='38.518 36.372 48.064 20.029 30.565 9.808 16.481 33.918 20.682 36.372 38.518 36.372'
              fill='#ecf2ff' stroke='#000654' stroke-width='3'></polygon>
            <line x1='55.777' y1='45.402' x2='1.5' y2='45.402' stroke='#000654' stroke-width='3'></line>
            <path
              d='M16.481,0c1.962,5.394,4.414,7.846,9.808,9.808-5.394,1.961-7.846,4.413-9.808,9.807-1.961-5.394-4.413-7.846-9.807-9.807,5.394-1.962,7.846-4.414,9.807-9.808Z'
              fill='#ff3860' stroke-width='0'></path>
            <path
              d='M42.18,18.626c-.422,1.832.145,3.752,1.495,5.061-1.832-.422-3.752.145-5.061,1.495.422-1.832-.145-3.752-1.495-5.061,1.832.422,3.752-.145,5.061-1.495Z'
              fill='#ff3860' stroke-width='0'></path>
          </svg>
          <div style="font-size: 32px; font-weight: 700;">VoteFlare</div>
        </div>
        <div style="font-size: 24px; font-weight: 500">
          Secure your Vote!
        </div>
      </div>
    </a>`,
      title: 'Embed Link to VoteFlare',
      desc: 'This is a basic a tag link that you can use to add a VoteFlare link to your website.',
      button: (
        <a href='/' target='_blank' rel='noreferrer'>
          <div
            style={{
              width: '250px',
              cursor: 'pointer',
              backgroundColor: 'rgb(224 242 254)',
              borderRadius: '14px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '14px',
              gap: '0px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <svg width={'32px'} viewBox='0 0 57.277 72.493'>
                <path
                  d='M55.488,44.924l-9.243-15.278c-.364-.602-1.018-.969-1.722-.965l-29.742.156c-.631.002-1.224.302-1.6.809L1.894,44.871c-.256.345-.394.763-.394,1.192v22.93c0,1.105.895,2,2,2h50.277c1.105,0,2-.895,2-2v-23.033c0-.365-.1-.724-.289-1.036Z'
                  stroke='#000654'
                  stroke-width='4'
                  fill='transparent'
                ></path>
                <line
                  x1='41.175'
                  y1='36.372'
                  x2='17.175'
                  y2='36.372'
                  stroke='#000654'
                  stroke-width='4'
                  stroke-linecap='square'
                ></line>
                <polygon
                  points='38.518 36.372 48.064 20.029 30.565 9.808 16.481 33.918 20.682 36.372 38.518 36.372'
                  fill='#ecf2ff'
                  stroke='#000654'
                  stroke-width='4'
                ></polygon>
                <line
                  x1='55.777'
                  y1='45.402'
                  x2='1.5'
                  y2='45.402'
                  stroke='#000654'
                  stroke-width='4'
                ></line>
                <path
                  d='M16.481,0c1.962,5.394,4.414,7.846,9.808,9.808-5.394,1.961-7.846,4.413-9.808,9.807-1.961-5.394-4.413-7.846-9.807-9.807,5.394-1.962,7.846-4.414,9.807-9.808Z'
                  fill='#ff3860'
                  stroke-width='0'
                ></path>
              </svg>
              <div
                style={{ fontSize: '32px', fontWeight: 700, color: 'black' }}
              >
                VoteFlare
              </div>
            </div>
            <div style={{ fontSize: '24px', fontWeight: 500, color: 'black' }}>
              Secure your Vote!
            </div>
          </div>
        </a>
      ),
    },
  ]
  return (
    <div className='w-screen min-h-screen bg-main-100 flex justify-center'>
      <div className='w-[550px] flex flex-col my-20 mb-40'>
        {OPTIONS.map((option, index) => (
          <>
            <div className='my-8 flex flex-col gap-4'>
              <div className='text-slate-100 text-3xl my-4'>{option.title}</div>
              {option.button}
              <div className='text-slate-400 text-xl my-4'>{option.desc}</div>
              <div className='text-white text-2xl'></div>
              <div className='bg-slate-800 p-8 rounded-lg overflow-hidden'>
                <CopyToClipboard text={option.code}>
                  <Button>
                    <ContentCopyIcon sx={{ fill: 'white' }} />
                  </Button>
                </CopyToClipboard>
                <code className='text-slate-200 text-[12px]'>
                  {option.code}
                </code>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  )
}
