import { useState, useEffect, useContext } from 'react'
import Img1 from './imgs/dec-2020-01.png'
import Img2 from './imgs/dec-2020-02.png'
import Img3 from './imgs/dec-2020-03.png'
import Img4 from './imgs/aug-2020-01.png'
import Img5 from './imgs/feb-2020-01.png'
import Img6 from './imgs/nov-2016-01.png'
import Img7 from './imgs/mar-2022-01.png'

import './other.css'

export const History = ({ page }) => {
  const spanish = (
    <>
      <h1 style={{ fontSize: '24px' }} className='history-head'>
        La historia de VoteFlare
      </h1>

      <div className='history-card'>
        <div className='history-card-left'>
          <h2 className='history'>Diciembre de 2020</h2>
        </div>
        <div className='history-card-right'>
          <h3>
            VoteFlare durante la segunda vuelta de las elecciones del estado de
            Georgia
          </h3>
          <p>
            Latanya Sweeney lideró el equipo que desarrolló e implementó una
            versión de grado de producción de VoteFlare utilizando la
            infraestructura de{' '}
            <a target={'_blank'} href={'https://mydatacan.org'}>
              https://mydatacan.org
            </a>{' '}
            MyDataCan. Este esfuerzo fue parte de una iniciativa de interés
            público llevada a cabo por la Iniciativa de Tecnología e Interés
            Público de la Universidad de Harvard y era gratuita para los
            electores del estado de Georgia durante la segunda vuelta de la
            elección senatorial. Los miembros del equipo de la profesora Sweeney
            incluyeron a Bradley Abruzzi, Pascal Delpe-Brice, Brad Frank, Eli
            Munn y Jinyan Zang. Los electores de Georgia se inscribieron a
            través de https://voteflare.org/ y el servicio monitoreaba su
            registro electoral en tiempo real y enviaba mensajes de texto o
            email si aparecía algo que debía llamar la atención del elector. El
            recién graduado de Harvard, Dhruv Gupta, y el estudiante de Harvard
            Sumhith Aradhyula, desarrollaron la tecnología necesaria para
            ofrecer transportación gratuita a las urnas, la cual se coordinaba a
            través de mensajes de texto, para aquellos usuarios que optaran por
            votar en el día de las elecciones. VoteFlare llevó a cabo decenas de
            miles de búsquedas sin ningún problema. Los electores dijeron que el
            sistema los libró de bastante ansiedad en cuanto a las elecciones y
            el quipo recibió muchísimos mensajes de agradecimiento por parte de
            los electores de Georgia después de las elecciones. Esta labor fue
            presentada por primera vez a través de
            <a
              target={'_blank'}
              href={
                'https://www.thecrimson.com/article/2020/12/24/harvard-students-voting-technology'
              }
            >
              {' '}
              https://www.thecrimson.com/article/2020/12/24/harvard-students-voting-technology/
            </a>
            ".
          </p>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <img
              className={'history-img'}
              src={Img1}
              alt='VoteFlare home page screenshot'
            />
            <img className={'history-img'} src={Img2} alt='MyDataCan logo' />
            <img className={'history-img'} src={Img3} alt='Axios logo' />
          </div>
        </div>
      </div>

      <div className='history-card'>
        <div className='history-card-left'>
          <h2 className='history'>Agosto de 2020</h2>
        </div>
        <div className='history-card-right'>
          <h3>Los prototipos de VoteFlare</h3>
          <p>
            Jinyan Zang y Daniel Gottesman desarrollaron un prototipo que
            demostró la usabilidad e interacción del usuario. Latanya Sweeney
            desarrolló y demostró prototipos de tecnología backend que
            funcionaron para cada una de las páginas web de los 48 estados y del
            Distrito de Columbia.
          </p>
          <img
            src={Img4}
            alt='VoteFlare prototype screenshot'
            className={'history-img'}
          />
        </div>
      </div>

      <div className='history-card'>
        <div className='history-card-left'>
          <h2 className='history'>Febrero de 2020</h2>
        </div>
        <div className='history-card-right'>
          <h3>Gov1430 explora el concepto de VoteFlare</h3>
          <p>
            La profesora Latanya Sweeney, a través de su curso en la universidad
            de Harvard titulado en inglés Gov1430 Tech Science to Save the
            World, (Tecnología científica para salvar el mundo)retó a los
            estudiantes a explorar la viabilidad y la utilidad de un servicio
            automatizado que monitoreara el registro de los electores. Jinyan
            Zang era el ayudante de cátedra del curso y ayudó a desarrollar los
            conceptos. Los estudiantes buscaron demostrar si la tecnología podía
            alcanzar esta meta en los 48 estados y en el distrito de Columbia.
            Los estudiantes que participaron fueron (en orden alfabético) Ryan
            Chung, Diego García, Kaitlyn Greta, Juan Guzmán, Pernilla Hamren,
            Jasmine Hyppolite, Aidan Keenan, Samuel Lurye, Paul Marino, Colin
            McGinn, David Netter, Kiera O’Brien, Jordy Rodríguez, Bruna Saraiva
            and Alyx Van Der Vorm.
          </p>
          <img
            src={Img5}
            alt='GOV 1430 course logo'
            className={'history-img'}
          />
        </div>
      </div>

      <div className='history-card'>
        <div className='history-card-left'>
          <h2 className='history'>Noviembre de 2016</h2>
        </div>
        <div className='history-card-right'>
          <h3>
            Las vulnerabilidades que se encontraron en las páginas web para
            electores
          </h3>
          <p>
            Latanya Sweeney, Jinyan Zang y JiSu Yoo, en el Data Privacy Lab de
            la Universidad de Harvard, lideraron un estudio que encontró que los
            electores podrían estar expuestos al robo de identidad en las
            páginas web del estado. Da'Von Boyd, Evelyn Chen, Asia DaCosta y
            Rachel Matheson (en orden alfabético) donde los pasantes de verano
            del programa 2016 Ciencia de la Tecnología trabajaron con ellos e
            identificaron algunas de las páginas web de los estados. Natalie
            Altman, Evelyn Chen y Anupama Jeevan (en orden alfabético) ayudaron
            con la automatización.
            <br />
            El estudio encontró lo siguiente:
            <br />
            Las páginas web de 35 estados y DC en el año 2016 estaban en una
            situación vulnerable al robo de identidad de los electores y a otros
            ataques de este tipo: un impostor podía hacer cambios a la
            información registrada de cualquier elector.
            <br />
            Un impostor necesitaba una combinación del nombre, la fecha de
            nacimiento, el género, la dirección, el número de seguro social o de
            licencia de conducir del elector.
            <br />
            La data relevante puede ser obtenida del gobierno, corredores de
            data o en el mercado negro del internet. El costo total de un ataque
            automatizado del 1% de todos los registros electorales vulnerables a
            través de toda la nación varía de entre $10,081 y $24,926,
            dependiendo de la fuente de datos que se utilice. Los estados eran
            menos costosos, por ejemplo $1 por Alaska y $1,020 por Illinois.
            <br />
            Un ataque para robar la identidad de los electores podría
            interrumpir una elección si los impostores someten cambios a las
            direcciones, borran los registros de los electores o solicitan
            papeletas para votar ausente. https://techscience.org/a/2017090601
            <br />
          </p>
          <img
            src={Img6}
            alt='Technology Science logo'
            className={'history-img'}
          />
        </div>
      </div>
    </>
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='px-20 bg-main-100'>
      <h1 style={{ fontSize: '24px' }} className='history-head'>
        History of VoteFlare
      </h1>
      <div className='history-card bg-main-100'>
        <div className='history-card-left'>
          <h2 className='history'>March 2022</h2>
        </div>
        <div className='history-card-right'>
          <h3>VoteFlare&trade; at the 2022 Texas Primary</h3>
          <p>
            Harvard’s Public Interest Tech Lab made VoteFlare available to
            voters in Texas free of charge during the Texas Primary. Team
            members (alphabetically): Bradley Abruzzi, Pascal Delpe-Brice,
            Jordan Buchman, Eli Munn, Josh Shank, Latanya Sweeney, and Josh
            Visnaw (Project Manager). The work was featured in{' '}
            <a href='https://www.govtech.com/security/securing-the-midterms-smarter-tools-watch-over-voter-records'>
              GovTech
            </a>
            .
          </p>
          <img src={Img7} alt='GovTech logo' className={'history-img'} />
        </div>
      </div>
      <div className='history-card'>
        <div className='history-card-left'>
          <h2 className='history'>December 2020</h2>
        </div>
        <div className='history-card-right'>
          <h3>VoteFlare&trade; at the Georgia Runoff Election</h3>
          <p className=' '>
            Latanya Sweeney led a team that developed and deployed a production
            grade version of VoteFlare&trade; using the{' '}
            <a href='https://mydatacan.org'>MyDataCan</a>&trade; infrastructure.
            The effort was part of Harvard&rsquo;s public interest tech
            initiative and was free to Georgia voters during the runoff
            Senatorial election. Professor Sweeney&rsquo;s team members included
            Bradley Abruzzi, Pascal Delpe-Brice, Brad Frank, Eli Munn and Jinyan
            Zang. Georgia voters signed up at{' '}
            <a href='https://voteflare.org/'>https://voteflare.org</a> and the
            service monitored their voter registrations in real-time and sent
            text or email messages if any issue arose that needed the
            voter&rsquo;s attention. Recent Harvard graduate, Dhruv Gupta, and
            Harvard student, Sumhith Aradhyula, developed accompanying
            technology to offer free rides to the polls, schedulable by text
            message, to those users who opted to vote on election day.
            VoteFlare&trade; performed tens of thousands of lookups flawlessly.
            Voters said the system relieved a lot of voter anxiety and the team
            received many thank you messages from Georgia voters afterwards. The
            work was first featured in{' '}
            <a href='https://www.axios.com/vote-flare-notification-registration-georgia-harvard-ad18a192-4329-4714-88d4-0292336a835c.html'>
              Axios{' '}
            </a>
            and the{' '}
            <a href='https://www.thecrimson.com/article/2020/12/24/harvard-students-voting-technology/'>
              Harvard Crimson
            </a>{' '}
            .
          </p>
          <div
            style={{ display: 'flex', flexDirection: 'column' }}
            className='bg-slate-600'
          >
            <img
              className={'history-img'}
              src={Img1}
              alt='VoteFlare home page screenshot'
            />
            <img className={'history-img'} src={Img2} alt='MyDataCan logo' />
            <img className={'history-img'} src={Img3} alt='Axios logo' />
          </div>
        </div>
      </div>
      <div className='history-card'>
        <div className='history-card-left'>
          <h2 className='history'>August 2020</h2>
        </div>
        <div className='history-card-right'>
          <h3>VoteFlare&trade; Prototypes</h3>
          <p>
            Jinyan Zang and Daniel Gottesman developed a prototype that
            demonstrated usability and user interaction. Latanya Sweeney
            developed and demonstrated technology back-end prototypes that
            worked for each of the websites for 48 states and the District of
            Columbia.
          </p>
          <img
            src={Img4}
            alt='VoteFlare prototype screenshot'
            className={'history-img'}
          />
        </div>
      </div>
      <div className='history-card'>
        <div className='history-card-left'>
          <h2 className='history'>February 2020</h2>
        </div>
        <div className='history-card-right'>
          <h3>Gov1430 Explores the VoteFlare Concept</h3>
          <p>
            Professor Latanya Sweeney challenged students in her Gov1430 Tech
            Science to Save the World course at Harvard to explore the viability
            and utility of an automated service that monitors voter
            registrations for voters. Jinyan Zang was the teaching fellow for
            the course and helped develop concepts. Students demonstrated
            whether technology could achieve this goal in 48 states and the
            District of Columbia. Students (listed alphabetically) were Ryan
            Chung, Diego Garcia, Kaitlyn Greta, Juan Guzman, Pernilla Hamren,
            Jasmine Hyppolite, Aidan Keenan, Samuel Lurye, Paul Marino, Colin
            McGinn, David Netter, Kiera O’Brien, Jordy Rodriguez, Bruna Saraiva
            and Alyx Van Der Vorm.
          </p>
          <img
            src={Img5}
            alt='GOV 1430 course logo'
            className={'history-img'}
          />
        </div>
      </div>
      <div className='history-card'>
        <div className='history-card-left'>
          <h2 className='history'>November 2016</h2>
        </div>
        <div className='history-card-right'>
          <h3>Vulnerabilities found in Voter Websites</h3>
          <p>
            Latanya Sweeney, Jinyan Zang and JiSu Yoo, in the Data Privacy Lab
            at Harvard University, led a study that found voters could be
            impersonated at state websites. Da&rsquo;Von Boyd, Evelyn Chen, Asia
            DaCosta, and Rachel Matheson (listed alphabetically) were the 2016
            Technology Science summer interns who worked with them and who
            identified some of the state websites. Natalie Altman, Evelyn Chen,
            and Anupama Jeevan (listed alphabetically) provided help with
            automation..&nbsp;
            <br />
            The study found:
            <br />
            <ul>
              <li>
                Websites for 35 states and DC in 2016 were vulnerable to voter
                identity theft attacks: an imposter could submit changes to
                voter registration information
              </li>
              <li>
                An imposter needed a combination of voter&rsquo;s name, date of
                birth, gender, address, Social Security Number, or
                Driver&rsquo;s License Number
              </li>
              <li>
                Relevant data can be acquired from government, data brokers, or
                darknet markets. Total cost of an automated attack against 1% of
                all vulnerable voter registrations nationwide ranged from
                $10,081 to $24,926 depending on the data source used. States
                cost less, e.g., $1 for Alaska and $1,020 for Illinois
              </li>
            </ul>
            A voter identity theft attack could disrupt an election by imposters
            submitting address changes, deleting voter registrations, or
            requesting absentee ballots. (
            <a href='https://techscience.org/a/2017090601'>published paper</a>)
          </p>
          <img
            src={Img6}
            alt='Technology Science logo'
            className={'history-img'}
          />
        </div>
      </div>
    </div>
  )
}
