import { useState, useEffect } from 'react';

import anime from 'animejs/lib/anime.es.js';


const TextAnimation = ({}) => {

  useEffect(() => {

    anime.timeline({
      easing: 'easeOutQuad',
      loop: true
    })
    .add({
      targets: "#check-box",
      duration: 1400,
      translateY: [0, 85],
    })
    .add({
      targets: "#notification",
      duration: 800,
      translateY: [0, -30],
      opacity: [0,1]
    })
    .add({
      targets: "#notification",
      delay: 1000,
      duration: 400,
      opacity: [1,0]
    })
    .add({
      targets: "#check-box",
      duration: 1400,
      translateY: [85, 0],
    })
    .add({
      targets: "#notification",
      duration: 800,
      translateY: [0, -30],
      opacity: [0,1]
    })
    .add({
      targets: "#notification",
      delay: 1000,

      duration: 400,
      opacity: [1,0]
    })

  }, []);




  return (
    <>

      <div class='checks-anim'>
        <div className='ch one'>
          <svg  className='checks-img-double-one' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1080 1080'>
            <g>
              <rect style={{fill: '#efefef'}} x='399.78' y='238.08' width='263.61' height='263.61'/>
              <path style={{fill: '#9e9e9e'}} d='M658.39,243.08V496.69H404.78V243.08H658.39m10-10H394.78V506.69H668.39V233.08Z'/>
            </g>
            <g>
              <rect style={{fill: '#efefef'}} x='399.78' y='608.74' width='263.61' height='263.61'/>
              <path style={{fill: '#9e9e9e'}} d='M658.39,613.74V867.35H404.78V613.74H658.39m10-10H394.78V877.35H668.39V603.74Z'/>
            </g>
          </svg>
          <svg id='check-box' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1080 1080' className='checks-img-double-two'>
            <path style={{ fill: '#8dce8d', stroke: '#008c00',  strokeMiterlimit: '10', strokeWidth: '7px' }} d='M458,319.83c2.43,1.71,5.5,3.31,7.86,5.62,19.37,18.89,38.6,37.92,57.88,56.89,4.94,4.86,9.93,9.67,14.87,14.53,2.38,2.35,4.08,2.07,5.22-1.16,18.46-52.16,48.4-98.13,78.65-143.75,25.9-39.08,53.62-77,80.53-115.35a50.43,50.43,0,0,1,3.45-4.89,4.15,4.15,0,0,1,3-1.32c.65.06,1.52,1.41,1.7,2.3q13.08,63.6,26.07,127.21c.82,3.95-1.52,5.19-4.38,6.4A252.55,252.55,0,0,0,679.61,297c-26.56,19.9-48.85,44-69,70.19A542.57,542.57,0,0,0,547.13,472.8c-4.59,10-4.89,10-12.76,2.33Q470.95,413,407.51,351c-3.91-3.83-3.71-5.54,1.18-8q21.53-10.59,43.08-21.07A58.78,58.78,0,0,1,458,319.83Z'/>
          </svg>
        </div>
        <div class='ch two'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1080 1080'>
            <g>
              <line style={{ fill: 'none', stroke: '#9e9e9e',  strokeLinecap: 'square', strokeWidth: '36px', strokeLinejoin:'round' }} x1='204.36' y1='540' x2='746.46' y2='540'/>
              <polygon style={{ fill: '#9e9e9e'}} points='720.2 629.75 875.64 540 720.2 450.25 720.2 629.75'/>
            </g>
          </svg>
        </div>

        <div class='ch three'>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1080 1080' className='checks-img-double-one'>
          <g>
            <g>
              <rect style={{ fill: '#efefef' }} x='359.07' y='174.4' width='361.86' height='731.2' rx='39.46'/>
              <path style={{ fill: '#7c7c7c' }} d='M681.47,177.9a36.06,36.06,0,0,1,36,35.95v652.3a36.06,36.06,0,0,1-36,36H398.53a36.06,36.06,0,0,1-36-36V213.85a36.06,36.06,0,0,1,36-35.95H681.47m0-7H398.53a43,43,0,0,0-43,42.95v652.3a43,43,0,0,0,43,43H681.47a43,43,0,0,0,43-43V213.85a43,43,0,0,0-43-42.95Z'/>
            </g>
            <path style={{ fill: '#afafaf' }} d='M623,177.9v4.92c0,11.82-7.66,21.49-17,21.49H474c-9.36,0-17-9.67-17-21.49V177.9'/>
          </g>
          </svg>
          <svg id='notification' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1080 1080' className='checks-img-double-two'>
            <rect style={{ fill: '#bedced' }} x='372.88' y='423.53' width='337.16' height='109' rx='12'/>
            <line style={{ fill: '#fff', stroke: '#209cee', strokeWidth: '4px', strokeMiterlimit: 10 }} x1='454.43' y1='451.68' x2='676.96' y2='451.68'/>
            <line style={{ fill: '#fff', stroke: '#209cee', strokeWidth: '4px', strokeMiterlimit: 10 }} x1='454.43' y1='478.03' x2='676.96' y2='478.03'/>
            <line style={{ fill: '#fff', stroke: '#209cee', strokeWidth: '4px', strokeMiterlimit: 10 }} x1='399.36' y1='504.37' x2='676.96' y2='504.37'/>
            <g>
              <path style={{ fill: '#ff3860', stroke: '#ff3860', strokeMiterlimit: 10 }} d='M412.36,448.75h.86a2,2,0,0,1,1.65,2l.06.05.17,0a9.33,9.33,0,0,1,7.69,6.5,18.9,18.9,0,0,1,.8,5.49c.07,1.77.16,3.54.37,5.3a9.76,9.76,0,0,0,3.85,6.91c.39.29.76.62,1.13.93a1.31,1.31,0,0,1,.22.24H396.35c.53-.43,1-.83,1.44-1.19a9.88,9.88,0,0,0,3.78-6.45,50.73,50.73,0,0,0,.45-6.15,22.09,22.09,0,0,1,.5-4.07,9.2,9.2,0,0,1,6.22-7.05c.65-.23,1.32-.36,2-.54a1.78,1.78,0,0,1,.6-1.41A7.38,7.38,0,0,1,412.36,448.75Z'/>
              <path style={{ fill: '#ff3860', stroke: '#ff3860', strokeMiterlimit: 10 }} d='M408.41,478h8.78c0,1.86-2.14,3.65-4.33,3.67S408.46,480,408.41,478Z'/>
            </g>
          </svg>
        </div>
      </div>

    </>
  );
}

export default TextAnimation;
