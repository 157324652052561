import _ from 'lodash'
import React, { useEffect, useReducer, useState } from 'react'
import { useForm, Controller, set } from 'react-hook-form'
import dayjs from 'dayjs'
import $axios from '../../tools/axiosWrapper'

export const Card = ({ form, d, i }) => {
  const [selected, setSelected] = useState(false)
  const DISPLAY = (d) => {
    let items = d.split('_')

    if (d.includes('nationaldob')) {
      return {
        state: items[1],
        birthdate: items[2],
        firstname: items[3],
        lastname: items[4],
        zip5: items[5],
        county_name: items[6],
      }
    } else if (d.includes('national')) {
      return {
        state: items[1],
        firstname: items[2],
        lastname: items[3],
        zip5: items[4],
        county_name: items[5],
        birthdate: items[6],
      }
    }
  }

  const SELECTED = form.watch('select')?.i
  return (
    <>
      <button
        className={`${
          form.watch('select')?.i == i
            ? 'border-green1 bg-slate-700 border-[3px]'
            : 'border-slate-100 border-[3px]'
        } w-[300px] min--[100px] p-2 rounded-[20px] flex flex-row justify-start`}
        onClick={() => {
          if (form.watch('select')?.i == i) {
            form.setValue('select', false)
          } else {
            form.setValue('select', { path: d, i: i })
          }
        }}
      >
        <div className='flex items-center h-[60px] px-2 font-bold'>
          <div className='text-2xl text-slate-100'>{`${i + 1 + '.'}`}</div>
        </div>
        <div className='w-full h-full'>
          <div className='w-full h-full rounded-[10px] p-1 flex flex-col items-start gap-2'>
            <div className='flex gap-2 text-[18px] font-bold text-slate-100'>
              <div>{_.capitalize(DISPLAY(d).firstname)}</div>
              <div>{_.capitalize(DISPLAY(d).lastname)}</div>
            </div>
            <div className=' text-slate-300 text-[18px] tracking-wider font-bold'>
              {DISPLAY(d).birthdate}
              {/* <div className='text-slate-400 text-[12px]'>{'(MM/DD/YYYY)'}</div> */}
            </div>
            <div className='text-slate-300 font-bold h-full text-[16px] gap-4 flex items-start justify-start'>
              <div>{DISPLAY(d).zip5}</div>
              <div>{_.capitalize(DISPLAY(d).county_name)}</div>
              <div>{DISPLAY(d).state}</div>
            </div>
          </div>
        </div>
      </button>
    </>
  )
}
