import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import 'react-phone-number-input/style.css'
import { Card } from './search/card'
import $axios from '../tools/axiosWrapper'
import CircularProgress from '@mui/material/CircularProgress'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

export const Select = ({ user, form }) => {
  useEffect(() => {}, [])
  const [load, setLoad] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (form.getValues('results')?.length == 1) {
      form.setValue('select', { path: form.getValues('results')[0], i: 0 })
    }
  }, [])
  console.log(form.watch('phone_number'))

  return (
    <>
      <div className={'flex flex-col items-center py-4 gap-4 px-2 font-noto'}>
        {form.getValues('results') &&
          form.getValues('results')?.map((d, i) => (
            <>
              <Card d={d} i={i} form={form} user={user} />
            </>
          ))}

        {form.getValues('noresults') && (
          <div className='px-6 flex flex-col gap-4 items-start'>
            <div className='text-red-400 text-[22px] font-bold'>No Results</div>
            <div className='text-slate-200 font-bold text-[18px]'>
              We didn't find you in our database. This could mean several
              things. Here are some steps you could take:
            </div>
            <div className='text-slate-400 text-[16px]'>
              1. Make sure you entered information correctly. Make sure your
              name matches what is on your ID.
            </div>
            <button
              className={`${'bg-orange-600'} font-noto font-regular text-white text-md font-bold rounded-[12px] px-4 py-2 flex justify-center drop-shadow-lg`}
              onClick={() => {
                form.setValue('step', 3)
              }}
            >
              Try Again
            </button>
            <div className='text-slate-400 text-[16px]'>
              2. Check your state's voter website. Most states have their own
              website where you can check your voter registration status.
            </div>
            <div className='text-slate-400 text-[16px]'>
              3. Make sure you are registered to vote.
            </div>
          </div>
        )}

        <div className='px-4'>
          <CircularProgress
            className={`${load ? 'visible' : 'invisible'}`}
            sx={{ color: '#18AA00' }}
          />
        </div>

        {!form.getValues('noresults') &&
          (_.has(form.getValues('select'), 'path') ? (
            <motion.button
              initial={{
                scale: 1,
              }}
              animate={{
                scale: 1.05,
              }}
              transition={{
                duration: 0.5,
                repeat: Infinity,
                repeatType: 'reverse',
                ease: 'easeInOut',
              }}
              onClick={() => {
                setLoad(true)
                $axios
                  .get('/voter/voter_schedule_setup', {
                    params: {
                      voterindex: form.getValues('select')?.path,
                      usersub: form.getValues('usersub'),
                    },
                  })
                  .then((res) => {
                    console.log(res)
                    setLoad(false)
                    form.setValue('voterinfo', res.data)
                    navigate('/profile')
                  })
                  .catch((err) => {
                    console.log(err)
                  })
              }}
              className={`bg-green1 my-8 w-[200px] rounded-[10px] text-white uppercase font-bold p-3 text-md`}
            >
              THIS IS ME
            </motion.button>
          ) : (
            <button
              onClick={() => {}}
              className={`bg-slate-800 my-8 w-[200px] rounded-[10px] text-white uppercase font-bold p-3 text-md`}
            >
              THIS IS ME
            </button>
          ))}
      </div>
    </>
  )
}
