import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import $axios from '../tools/axiosWrapper'
import { Graph } from './graph'
import { motion } from 'framer-motion'
import { Logo } from '../assets/logoWhite'

export const Main = ({ setPhone, user, setUser, form, removePhone }) => {
  const navigate = useNavigate()
  const oneDay = 24 * 60 * 60 * 1000
  const firstDate = new Date()
  const secondDate = new Date(2024, 10, 5)
  const diffDays = Math.floor(Math.abs((secondDate - firstDate) / oneDay))

  return (
    <main className='w-screen min-h-screen flex bg-main-100 justify-center font-noto'>
      <div className='w-screen min-h-screen max-w-lg min-h-[800px] flex bg-main-100'>
        <div className='p-8 pt-4'>
          <div className='flex items-center'>
            <Logo width={40} />
            <div className='pl-3 mt-2 font-noto font-regular text-slate-100 text-[40px] font-semibold flex'>
              VoteFlare<div className='text-[20px] font-light'>&trade;</div>
            </div>
          </div>

          <div className='font-noto font-regular text-main-300 text-2xl my-4 mx-1 leading-8'>
            Secure your Vote!
          </div>

          <div className='w-full h-[150px] relative'>
            {user == false && (
              <motion.button
                initial={{ opacity: 0, scale: 0.2 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                className={`${'bg-green1'} absolute mt-10 mb-12 font-noto font-regular text-white text-md font-bold rounded-[12px] px-12 py-4 flex justify-center drop-shadow-lg`}
                onClick={() => navigate('/login')}
              >
                BEGIN
              </motion.button>
            )}

            {user?.Enabled &&
            _.values(form.watch('voterinfo')?.electioninfo)?.filter(
              (d) => d !== ''
            ).length == 0 ? (
              <button
                className={`${'bg-orange-600'} absolute mt-10 mb-12 font-noto font-regular text-white text-md font-bold rounded-[12px] px-12 py-4 flex justify-center drop-shadow-lg`}
                onClick={() => navigate('/register')}
              >
                Find Voter Record
              </button>
            ) : (
              <></>
            )}

            {user?.Enabled &&
            _.values(form.watch('voterinfo')?.electioninfo)?.filter(
              (d) => d !== ''
            ).length !== 0 ? (
              <button
                className={`bg-green-800 absolute mt-10 mb-12 font-noto font-regular text-white text-md font-bold rounded-[12px] px-12 py-4 flex justify-center drop-shadow-lg gap-3`}
                onClick={() => navigate('/profile')}
              >
                <AdminPanelSettingsIcon /> View Profile
              </button>
            ) : (
              <></>
            )}
          </div>

          <div className='font-noto font-regular text-main-200 text-lg my-4 mt-10 mx-1'>
            Stay informed and empowered— our tool monitors your voter registration and delivers real-time updates on your status, alerting you when action is needed.
          </div>

          {/* <div className='ml-[-20px] mt-12'>
            <Graph />
          </div> */}
          {/* 
          <div className='font-noto font-regular text-main-300 text-xl mt-8 pr-10'>
            We offer the ability for you to be notified if your voter status
            changes unexpectedly.
          </div> */}
          {/* {user && (
            <button
              className='text-slate-900 p-2 px-2 mt-40 rounded-full bg-red-700 font-bold'
              onClick={() => {
                $axios
                  .post('/auth/delete')
                  .then((res) => {
                    console.log(res)
                    removePhone()
                    setUser(false)
                  })
                  .catch((err) => {
                    console.log(err)
                  })
                  .finally(() => {
                    navigate(0)
                  })
              }}
            >
              DELETE USER (TESTING ONLY)
            </button>
          )} */}
        </div>
      </div>
    </main>
  )
}
