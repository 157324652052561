import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { Phone } from './1_phone'
import { Verify } from './2_verify'
import React, { useEffect } from 'react'
import { Form } from '../register/form'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import LockPersonIcon from '@mui/icons-material/LockPerson'

export const Login = ({ form, user, setUser, setPhone }) => {
  const navigate = useNavigate()

  const onSubmit = (data) => {}

  useEffect(() => {
    form.setValue('step', 1)
  }, [])

  const INSTRUCT = [
    {
      step: 1,
      p: 'Login or Signup with your phone number',
      icon: (s, c) => <PhoneIphoneIcon sx={{ fontSize: s, color: c }} />,
    },
    {
      step: 2,
      p: 'Enter the 6-digit code we sent you',
      icon: (s, c) => <LockPersonIcon sx={{ fontSize: s, color: c }} />,
    },
  ]

  return (
    <main className='w-screen min-h-screen flex bg-main-100 justify-center font-noto'>
      <div className='w-screen min-h-screen max-w-lg min-h-[800px] flex justify-center bg-main-100'>
        <div className='p-8'>
          <form className='pr-px' onSubmit={form.handleSubmit(onSubmit)}>
            <div className='mb-10 mt-4 w-full h-[30px] pr-2'>
              <div className='font-noto font-bold flex justify-between'>
                {INSTRUCT.map((d, i) => {
                  return (
                    <div
                      className={`w-1/2 h-[30px] flex items-center justify-around px-1`}
                    >
                      <div
                        className={`flex flex-row justfiy-center items-center w-full h-[10px] rounded-full ${
                          form.watch('step') > d.step
                            ? 'bg-green-900'
                            : 'bg-slate-900'
                        }`}
                      >
                        <div
                          className={`h-[30px] w-[30px] rounded-full bg-transparent flex items-center justify-center`}
                        >
                          <div>
                            {d.icon(
                              30,
                              form.watch('step') == d.step
                                ? 'white'
                                : form.watch('step') > d.step
                                ? '#15803d'
                                : '#475569'
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className=' mb-8 font-noto text-slate-100 text-2xl font-bold'>
              {INSTRUCT[form.watch('step') - 1]?.p}
            </div>
            <div
              className={
                'w-full max-w-sm min-h-20 mt-2 rounded-[16px] border-2 border-orange'
              }
            >
              <div className={'flex flex-col items-center'}>
                <div className={'p-1'}>
                  {form.watch('step') === 1 && (
                    <Phone form={form} setPhone={setPhone} />
                  )}
                  {form.watch('step') === 2 && (
                    <Verify form={form} setPhone={setPhone} setUser={setUser} />
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  )
}
