import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import 'react-phone-number-input/style.css'
import dayjs from 'dayjs'
import Skeleton from '@mui/material/Skeleton'
import { motion } from 'framer-motion'
export const ElectionInfo = ({ user, form, loading }) => {
  const ELECTIONINFOFILTER = {
    nameinfo: {
      t: 'name',
      r: 'Name',
    },
    ageinfo: {
      t: 'Age Info',
      r: '',
    },
    voter_status: { t: 'voter status' },
    registrationinfo: { t: 'registration info' },
    precinctinfo: { t: 'precinct' },
    partyinfo: { t: 'party' },
    addressinfo: { t: 'address' },
    voterinfo: { t: 'voter info' },
    ballotinfo: { t: 'ballot info' },
  }

  return (
    <>
      <div className={'flex flex-col items-between pb-4 min-h-[200px]'}>
        <div className='min-h-[100px] w-full bg-slate-700 rounded-t-[16px] mb-4 p-5 '>
          <div className='text-[22px] font-bold text-slate-200 tracking-[1px]'>
            Election Info
          </div>
          <div className='text-slate-300 text-[16px]'>
            Your record on file with the state.
          </div>
        </div>
        {form.watch('electioninfo') == undefined && !loading ? (
          <>
            <motion.div
              className='text-red-300 text-[16px] px-4'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 4 }}
            >
              We did not find a record for you.
            </motion.div>
          </>
        ) : (
          <></>
        )}
        {form.watch('electioninfo') !== undefined &&
          _.keys(ELECTIONINFOFILTER)?.map((d, i) => {
            let raw = form.watch('voterinfo')?.electioninfo[d]
            return (
              raw && (
                <div className='w-[260px] flex flex-col justify-between mb-2 px-7'>
                  <div className='text-[13px] text-slate-400 capitalize'>
                    {ELECTIONINFOFILTER[d].t || ''}
                  </div>
                  <div className='min-h-[20px] w-full'>
                    {!loading ? (
                      <div className='text-left font-bold'>
                        {raw?.replace(ELECTIONINFOFILTER[d].r, '')}
                      </div>
                    ) : (
                      <Skeleton variant='rounded' width={210} height={20} />
                    )}
                  </div>
                </div>
              )
            )
          })}
      </div>
    </>
  )
}
