import { Outlet, useNavigate } from 'react-router-dom'
import a from '../assets/imgs/pitl.svg'
import b from '../assets/imgs/newamerica.svg'
import c from '../assets/imgs/savetheworld.png'
import d from '../assets/imgs/dataprivacy.png'
import e from '../assets/imgs/mydatacan.png'
export const Foot = ({ user }) => {
  const navigate = useNavigate()

  return (
    <>
      <footer className='w-full h-24 bg-main-100 border-t border-main-200 flex justify-center items-center gap-4'>
        <button
          className='text-main-400 p-2 hidden sm:block'
          onClick={() => navigate('/about')}
        >
          About
        </button>
        <button
          className='text-main-400 p-2'
          onClick={() =>
            window.open(
              'https://docs.google.com/forms/d/e/1FAIpQLSeqxVoz8UwO6DYPvVeZWLrbMJTO9d19CzCP5G4Zy3l5bEasXg/viewform',
              '_blank'
            )
          }
        >
          Contact
        </button>
        <button
          className='text-main-400 p-2'
          onClick={() => navigate('/developers')}
        >
          Developers
        </button>

        <button
          className='text-main-400 p-2 hidden sm:block'
          onClick={() => navigate('/history')}
        >
          History
        </button>
        <button
          className='text-main-400 p-2 hidden sm:block'
          onClick={() => navigate('/terms')}
        >
          Terms
        </button>
      </footer>
    </>
  )
}
