import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import { useForm, Controller } from 'react-hook-form'
import { Button } from '@mui/material'
import $axios from '../tools/axiosWrapper'
import { phone } from 'phone'
import CircularProgress from '@mui/material/CircularProgress'
import 'react-phone-number-input/style.css'
import './phone.css'
import { useNavigate } from 'react-router-dom'

export const Phone = ({ form, setPhone }) => {
  const [value, setValue] = useState('')
  const [valid, setValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  return (
    <>
      <div className={'flex flex-col items-center'}>
        <div className={'flex items-center ml-4'}>
          <div className='border-b border-b-2 border-b-slate-800 my-2 mr-6'>
            <input
              className='r w-full bg-main-100 font-noto font-regular text-main-300 text-2xl py-1 px-2 tracking-wide rounded-[10px]'
              autoFocus
              name='tel'
              autoComplete='tel'
              placeholder='+1 (123) 456-7890'
              onChange={(e) => {
                let value = e.target.value
                form.setValue('phone_number', phone(value).phoneNumber)
                setValid(phone(value)?.isValid || false)
              }}
              defaultCountry='US'
              type='tel'
            />
          </div>
        </div>
        <div className='w-full flex justify-between items-center'>
          <div className='px-4'>
            <CircularProgress
              className={`${loading ? 'visible' : 'invisible'}`}
              sx={{ color: '#18AA00' }}
            />
          </div>
          <button
            type='submit'
            disabled={!valid}
            className={`${
              valid ? 'border-green1' : 'border-slate-800'
            } border-4 rounded-[12px] m-2 px-4 py flex justify-center`}
            onClick={() => {
              setLoading(true)
              setPhone(form.getValues('phone_number'))
              console.log('---form.getValues', form.getValues('phone_number'))
              $axios
                .post('/auth/login', {
                  phone_number: form.getValues('phone_number'),
                })
                .then((res) => {
                  setLoading(false)
                  console.log(res)
                  form.setValue('step', 2)
                  form.setValue('state', res.data.state)
                  form.setValue('usersub', res?.data?.user?.sub)
                })
                .catch((err) => {
                  console.log('---e', err)
                })
              // .finally(() => {
              //   navigate('/register')
              // })
            }}
          >
            <svg viewBox='0 -960 960 960' height={48} width={48}>
              <path
                className={valid ? 'fill-green1' : 'fill-slate-800'}
                d='M647-440H200q-17 0-28.5-11.5T160-480q0-17 11.5-28.5T200-520h447L451-716q-12-12-11.5-28t12.5-28q12-11 28-11.5t28 11.5l264 264q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L508-188q-11 11-27.5 11T452-188q-12-12-12-28.5t12-28.5l195-195Z'
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  )
}
