import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { ElectionInfo } from './electioninfo'
import { VoterInfo } from './voterinfo'

import $axios from '../tools/axiosWrapper'
import { set } from 'react-hook-form'

import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'

export const Profile = ({ user, form }) => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    console.log(form.getValues('usersub'))
    setLoading(true)
    form.getValues('usersub') &&
      $axios
        .get('/voter/voter_info_get', {
          params: {
            usersub: form.getValues('usersub'),
          },
        })
        .then((res) => {
          form.setValue('voterinfo', res?.data)
          form.setValue('electioninfo', res?.data?.electioninfo)
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
        })
  }, [form.getValues('usersub')])

  return (
    <main className='w-screen min-h-screen flex bg-main-100 justify-center font-noto'>
      <div className='w-screen min-h-screen max-w-lg min-h-[800px] flex bg-main-100'>
        <div className='p-8'>
          {form.watch('electioninfo') !== undefined &&
          form.watch('voterinfo')?.status == 'active' ? (
            <div className='text-green-300 font-bold p-4 mb-10 w-full max-w-sm min-h-20 mt-2 rounded-[20px] border-4 border-green1 animate-in'>
              <div className='flex items-center gap-2'>
                <PhoneIphoneIcon sx={{ fontSize: 38 }} />
                <div className='text-[40px]'>Alerts On</div>
              </div>
              <div className='text-[21px] text-slate-300 pl-11'>
                You are now signed up for VoteFlare!
              </div>
              <div className='text-[15px] text-slate-400 pl-11 my-2'>
                You will receive SMS alerts upon if we detect any changes in
                your voter registration status.
              </div>
            </div>
          ) : (
            <></>
          )}
          <div
            className={
              'my-20 w-full max-w-sm min-h-20 mt-2 rounded-[20px] border-4 border-slate-500 animate-in'
            }
          >
            <ElectionInfo user={user} form={form} loading={loading} />
            {form.watch('electioninfo') == undefined && !loading ? (
              <div className={'w-full max-w-sm min-h-20 ml-10 rounded-[20px]'}>
                <button
                  className={`${'bg-red-600'} font-noto font-regular text-white text-md font-bold rounded-[12px] px-4 py-2 flex justify-center drop-shadow-lg`}
                  onClick={() => navigate('/register')}
                >
                  Try Lookup Again
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div
            className={
              'w-full max-w-sm min-h-20 mt-2 rounded-[20px] border-4 border-slate-500 mb-2 animate-in'
            }
          >
            <VoterInfo user={user} form={form} loading={loading} />
          </div>
        </div>
      </div>
    </main>
  )
}
