import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import 'react-phone-number-input/style.css'
import { useNavigate } from 'react-router-dom'

import Skeleton from '@mui/material/Skeleton'

export const VoterInfo = ({ user, form, loading }) => {
  const VOTERINFO = {
    firstname: {
      t: 'first',
    },
    lastname: {
      t: 'last',
    },
    zip5: { t: 'zipcode' },
    voter_county: { t: 'voter county' },
    state2letter: { t: 'state' },
    birthdate_yyyymmdd: { t: 'birthdate' },
    phonenumber: { t: 'phone number' },
    last4ssn: { t: 'last 4 SSN' },
    stateid: { t: 'state id' },
    partnershare: { t: 'partner share' },
    // status: { t: 'status' },
    lookupfirstname: { t: 'lookup first name' },
    lookuplastname: { t: 'lookup last name' },
    lookupcounty: { t: 'lookup by county' },
  }
  const navigate = useNavigate()

  return (
    <>
      <div className={'flex flex-col items-between pb-4'}>
        <div className='h-[100px] w-full bg-slate-700 rounded-t-[16px] mb-4 p-5'>
          <div className='text-[22px] font-bold text-slate-200 tracking-[1px]'>
            Voter Info
          </div>
          <div className='text-slate-300 text-[16px]'>
            Information you gave us.
          </div>
          <div className={'w-full max-w-sm min-h-20 mt-2 rounded-[20px]'}>
            <button
              className={`${'bg-orange-600'} font-noto font-regular text-white text-md font-bold rounded-[12px] px-4 py-2 flex justify-center absolute right-4 top-4`}
              onClick={() => navigate('/register')}
            >
              Edit
            </button>
          </div>
        </div>

        {_.keys(VOTERINFO)?.map((d, i) => {
          let raw = form.watch('voterinfo')?.[d]
          return (
            <>
              <div className='w-[260px] flex flex-col justify-between mb-2 px-7'>
                <div className='text-[13px] text-slate-400 capitalize'>
                  {VOTERINFO[d].t}
                </div>
                <div className='min-h-[20px] w-full'>
                  {!loading ? (
                    <div className='text-left font-bold'>{raw}</div>
                  ) : (
                    <Skeleton variant='rounded' width={210} height={20} />
                  )}
                </div>
              </div>
            </>
          )
        })}
      </div>
    </>
  )
}
