export const Logo = ({ width }) => (
  <>
    <svg width={width} viewBox='0 0 57.277 72.493'>
      <path
        d='M55.488,44.924l-9.243-15.278c-.364-.602-1.018-.969-1.722-.965l-29.742.156c-.631.002-1.224.302-1.6.809L1.894,44.871c-.256.345-.394.763-.394,1.192v22.93c0,1.105.895,2,2,2h50.277c1.105,0,2-.895,2-2v-23.033c0-.365-.1-.724-.289-1.036Z'
        stroke={'white'}
        strokeWidth={3}
        fill='transparent'
      />
      <line
        x1='41.175'
        y1='36.372'
        x2='17.175'
        y2='36.372'
        stroke={'white'}
        strokeWidth={3}
        strokeLinecap='square'
      />
      <polygon
        points='38.518 36.372 48.064 20.029 30.565 9.808 16.481 33.918 20.682 36.372 38.518 36.372'
        fill={'#ecf2ff'}
        stroke={'white'}
        strokeWidth={3}
      />
      <line
        x1='55.777'
        y1='45.402'
        x2='1.5'
        y2='45.402'
        stroke={'white'}
        strokeWidth={3}
      />
      <path
        d='M16.481,0c1.962,5.394,4.414,7.846,9.808,9.808-5.394,1.961-7.846,4.413-9.808,9.807-1.961-5.394-4.413-7.846-9.807-9.807,5.394-1.962,7.846-4.414,9.807-9.808Z'
        fill={'#ff3860'}
        strokeWidth={0}
      />
    </svg>
  </>
)
