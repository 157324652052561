import _ from 'lodash'
import React, { useEffect, useReducer, useState } from 'react'
import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { DateField } from '@mui/x-date-pickers/DateField'
import { useForm, Controller } from 'react-hook-form'
import $axios from '../../tools/axiosWrapper'
import 'react-phone-number-input/style.css'
import zips from '../../assets/data/min.json'
import { useToggle } from 'usehooks-ts'
import LinearProgress from '@mui/material/LinearProgress'
import { Checkbox } from '@mui/material'

export const SearchInput = ({ user, form, setResults }) => {
  const [load, setLoad] = useState(false)

  const [value, toggle, setValue] = useToggle()
  const filterOptions = createFilterOptions({
    ignoreCase: true,
    matchFrom: 'start',
    limit: 10,
  })

  const formCheck = useForm({})

  const STATES = {
    // CA: { required: ['stateid', 'ssn4'] },
    // DE: { required: ['stateid'] },
    // HI: { required: ['stateid', 'ssn4'] },
    // KY: { required: ['ssn4'] },
    // NV: { required: ['stateid', 'ssn4'] },
    // VT: { required: ['stateid', 'ssn4'] },
    // VA: { required: ['ssn4'] },
  }

  // useEffect(() => {
  //   if (
  //     _.has(form.watch(), 'given_name') &&
  //     _.has(form.watch(), 'family_name') &&
  //     _.has(form.watch(), 'birthdate') &&
  //     _.has(form.watch(), 'zip')
  //   ) {
  //     form.setValue('isValid', 'true')
  //   }
  // }, [form.watch()])

  const PASS =
    _.has(form.watch(), 'given_name') &&
    _.has(form.watch(), 'family_name') &&
    _.has(form.watch(), 'birthdate') &&
    formCheck.watch('1') &&
    formCheck.watch('2') &&
    formCheck.watch('3')

  return (
    <>
      <div
        className={
          'w-full flex flex-col justify-start items-center gap-6 mt-10'
        }
      >
        <div className='px-4 text-slate-100 mt-[-10px] text-[14px]'>
          Enter the full name shown on your ID
        </div>
        <Controller
          control={form.control}
          name='given_name'
          required
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              // className='w-[200px] bg-main-400 font-noto font-regular text-main-100 text-2xl py-1 px-2 tracking-wide rounded-[10px]'
              id='given_name'
              onChange={onChange}
              onBlur={onBlur}
              selected={value}
              placeholder='Full First Name'
            />
          )}
        />

        <Controller
          control={form.control}
          required
          name='family_name'
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              // className='w-[200px] bg-main-400 font-noto font-regular text-main-100 text-2xl py-1 px-2 tracking-wide rounded-[10px]'
              id='family_name'
              onChange={onChange}
              onBlur={onBlur}
              selected={value}
              placeholder='Last Name'
            />
          )}
        />

        <Autocomplete
          className='w-[200px] font-noto font-regular text-main-100 text-2xl tracking-wide rounded-[10px]'
          disablePortal
          filterOptions={filterOptions}
          id='combo-box-demo'
          options={zips}
          noOptionsText='No zipcodes'
          onChange={(e, value) => {
            _.keys(value)
              .filter((d) => d !== 'county_fips')
              .forEach((key) => form.setValue(key, value[key]))
          }}
          //   defaultValue={test ? DEFAULT.zip5 : ''}
          getOptionLabel={(option) => option.zip.toString()}
          renderInput={(params) => <TextField {...params} label='Zipcode' />}
          renderOption={(props, option) => {
            const { className, ...rest } = props
            return (
              <li {...props}>
                <div>{option.zip.toString() + ', ' + option.county_name}</div>
              </li>
            )
          }}
        />

        <Controller
          control={form.control}
          name='dayjs'
          required
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateField
                // type='date'
                // className='w-[200px] bg-main-400 font-noto font-regular text-main-100 text-2xl py-1 px-2 tracking-wide rounded-[10px]'
                id='birthdate'
                name='birthdate'
                label='Birthdate'
                // value={value}
                inputRef={ref}
                onChange={(date) => {
                  onChange(date)
                  console.log(date)
                  form.setValue(
                    'birthdate',
                    form.getValues('dayjs').format('YYYYMMDD')
                  )
                }}
              />
            </LocalizationProvider>
          )}
        />
        <div className='p-4 my-4 text-slate-100 mt-[-10px] text-[14px] flex flex-col gap-4 p-2'>
          <div className='flex items-center'>
            <Checkbox
              onClick={() => formCheck.setValue('1', !formCheck.getValues('1'))}
            />
            <div className='px-4 text-slate-100 text-[12px]'>
              I accept and agree to be bound by the VoteFlare Terms of service
              (voteflare.org/terms).
            </div>
          </div>

          <div className='flex items-center'>
            <Checkbox
              onClick={() => formCheck.setValue('2', !formCheck.getValues('2'))}
            />
            <div className='px-4 text-slate-100 text-[12px]'>
              I understand that VoteFlare™ monitors my voter registration status
              by periodically checking my Home State’s Voter Registration
              Database. VoteFlare™ may periodically visit the webpage of that
              database, enter my Required Information, and record the
              registration information reported back by the database, as of that
              date and time. VoteFlare™ may periodically obtain the state’s most
              recent Registered Voter List and compare my Required Information
              against that latest list.
            </div>
          </div>
          <div className='flex items-center'>
            <Checkbox
              onClick={() => formCheck.setValue('3', !formCheck.getValues('3'))}
            />
            <div className='px-4 text-slate-100 text-[12px]'>
              VoteFlare™ will send me a text message to tell me about the
              following developments based on those lookups: VoteFlare™ has
              started the service or has found that my voter registration status
              has changed, or a text message that contains a timely reminder.
              You can log into voteflare.org to see more details. We will not
              ask you for any personal information in any of these
              communications. See voteflare.org/terms for more details.
            </div>
          </div>
        </div>

        {/* {STATES.hasOwnProperty(form.watch('state_id')) &&
          STATES[form.getValues('state_id')].required.map((d) => (
            <>
              <Controller
                control={form.control}
                name={d}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    id={d}
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    placeholder={_.capitalize(d)}
                  />
                )}
              />
            </>
          ))} */}

        <button
          type='submit'
          disabled={!PASS}
          className={`${
            PASS ? 'bg-green1' : 'bg-slate-800'
          } font-noto font-regular text-white text-md font-bold rounded-[12px] px-12 py-4 flex justify-center mb-8`}
          onClick={() => {
            setLoad(true)

            const send = {
              birthdate_yyyymmdd: form.getValues('birthdate'),
              firstname: form.getValues('given_name'),
              lastname: form.getValues('family_name'),
              state2letter: form.getValues('state_id'),
              zip5: form.getValues('zip'),
              voter_county:
                form.getValues('county_name') == 'Anchorage'
                  ? 'MUNICIPALITY OF ANCHORAGE'
                  : form.getValues('county_name'),
              stateid: '',
              last4ssn: '',
              phone_number: form.getValues('phone_number') || '+10000000000',
              language_website: 'English',
              usersub: form.getValues('usersub'),
            }

            console.log(send)

            $axios
              .post('/voter/voter_register50_encrypt', { ...send })
              .then((res) => {
                $axios
                  .get('/voter/voter_lookup50_now', {
                    params: {
                      usersub: form.getValues('usersub'),
                    },
                  })
                  .then((res) => {
                    console.log(res.data)
                    setLoad(false)
                    form.setValue('results', _.uniq(res.data))
                    form.setValue('step', 4)
                    form.setValue('noresults', false)
                  })
                  .catch((err) => {
                    console.log(err)
                    form.setValue('step', 4)
                    form.setValue('noresults', true)
                  })
              })
              .catch((err) => {
                form.setValue('step', 4)
                form.setValue('noresults', true)
                console.log(err)
              })
          }}
        >
          FIND VOTER RECORD
        </button>
        <div className='h-4 w-full'>{load && <LinearProgress />}</div>
      </div>
    </>
  )
}
